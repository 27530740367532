import React from 'react';
import { getFormatNumberWithCommas } from '../../modules/timeline/components/EnterButton/utils';
import { AppContext } from '../../apollo/wrap-root-element';
import { navigate } from 'gatsby';
import CurrencySwitcher from '../Header/currencyswitcher/CurrencySwitcher';

const LanguageDonationCard = ({ languageDonationCard, raisedMoney, raisedPercentage, languageProjectId }) => {
  const countryImageUrl = `https://flagcdn.com/${languageDonationCard.flagCode.text}.svg`;
  const { donatingProjectId, setDonatingProjectId } = React.useContext(AppContext);

  return (
    <div
      className="cols"
      onClick={() => {
        setDonatingProjectId(languageProjectId);
        navigate('#donate-box');
      }}
      style={{ cursor: 'pointer' }}
    >
      <div className="language-donation-card bg-white">
        <div>
          <img className="thumbnail-image" src={countryImageUrl} alt={countryImageUrl} />
          <p className="title">{languageDonationCard?.languageName?.text}</p>
          <div>
            <p style={{ marginTop: '24px', marginLeft: { raisedPercentage }, color: '#fecb04' }}>{raisedPercentage}</p>
          </div>
          <div className="progress-track">
            <div className="progress-thumb" style={{ width: raisedPercentage }}></div>
          </div>
          <div className="goal-container">
            <p>
              <b>
                <CurrencySwitcher price={+raisedMoney} isCommaSeparated={true} />
              </b>{' '}
              Raised
            </p>
            <p>
              <b>
                <CurrencySwitcher price={+languageDonationCard?.goal} isCommaSeparated={true} />
              </b>
              Goal
            </p>
          </div>
          {/* <p className="descriptions">{languageDonationCard?.shortDescriptions?.text}</p> */}
        </div>
        <div className="btn-group-donate">
          {/* <a
            href={`/donate/${languageDonationCard.flagCode.text}`}
            className="btn btn-md  btn-outline btn-tour-jumbotron give-button">
            Read More
          </a> */}
          <button
            onClick={() => {
              setDonatingProjectId(languageProjectId);
              navigate('#donate-box');
            }}
            className="btn btn-md btn-primary-ii btn-tour-jumbotron give-button"
          >
            Donate
          </button>
        </div>
      </div>
    </div>
  );
};

export default LanguageDonationCard;
