import React, { useState } from 'react';

import { getVideoUrl, getVideoThumbnail } from '../../utils/functions';

import iconPlayBlk from '../../images/icon-play-blk.svg';

export default function LanguageVideoItem({ videoId, thumbnailURL }) {
  const [showVideo, setShowVideo] = useState(false);
  const _thumbnailURL = thumbnailURL ? thumbnailURL : getVideoThumbnail(videoId);
  const videoUrl = getVideoUrl(videoId);

  return (
    <div className="carousel pd-x-sm la-pn slideshow-container">
      <div className="carousel-stage">
        <div className="carousel-item carousel-video media ratio_21-9">
          <iframe
            width="560"
            height="315"
            src={`${videoUrl}${showVideo ? '&autoplay=1&rel=0' : ''}`}
            style={{ display: showVideo ? 'inherit' : 'none' }}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          {!showVideo && (
            <>
              <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${_thumbnailURL})` }}></div>
              <div className="media-content">
                <div className="btn btn-sm btn-primary-ii btn-play-icon" onClick={() => setShowVideo(true)}>
                  <img src={iconPlayBlk} alt="Icon Play" />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
