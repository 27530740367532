import { graphql } from 'gatsby';
import React, { useContext, useState } from 'react';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';

import WatchFilterSlider from '../components/Carousel/WatchFilterSlider';
import LanguageDonationCard from '../components/Donation/LanguageDonationCard';
import { useFetch } from '../hooks';
import Donation from '../components/Donation';
import { getListDonationProjectForSelect } from '../utils/functions';
import { AppContext } from '../apollo/wrap-root-element';

import renderHtml from 'htmr';
import { decode } from 'html-entities';

import ComboBoxSelect from '../components/Form/ComboBox';
import LanguageVideoItem from '../components/Donation/LanguageVideoItem';
import { Link } from 'gatsby';
import { currencySymbolMap } from '../components/Header/currencyswitcher/CurrencySwitcher';
import { getFormatNumberWithCommas } from '../modules/timeline/components/EnterButton/utils';

const LanguageDonationPage = (props) => {
  const {
    data: { bgGive, patreonThumb, languageDonationPage, donationsDetails },
  } = props;

  const [searchKey, setSearchKey] = useState('');
  const [selectedDonatedProject, setSelectedProject] = React.useState();
  const { currency, currenciesRate } = useContext(AppContext);
  const [selectedRate] = !!currenciesRate?.length && currenciesRate.filter((elm) => elm.currency === currency);

  const languageDonationDetailsData = languageDonationPage.edges[0].node.data;
  const donationDetailsData = donationsDetails.edges[0].node.data;
  const languageSortData = languageDonationDetailsData.body.sort((a, b) => {
    var firstLetterA = a.primary.languageName.text.charAt(0).toLowerCase();
    var firstLetterB = b.primary.languageName.text.charAt(0).toLowerCase();
    if (firstLetterA < firstLetterB) {
      return -1;
    } else if (firstLetterA > firstLetterB) {
      return 1;
    } else {
      // If first letters are the same, use normal string comparison
      return 1;
    }
  });
  const [allDonationLanguageProject, setAllDonationLanguageProject] = React.useState(languageSortData);

  const [selectedCat, setSelectedCat] = useState('all');
  const [getLanguageProjectRaisedMoney, raisedMoneyDataForLanguage, error, loading] = useFetch();
  const { donatingProjectId, setDonatingProjectId } = React.useContext(AppContext);

  const customProjectsLists = getListDonationProjectForSelect(
    donationDetailsData.projects,
    languageDonationDetailsData.body
  );

  const projectsLists = getListDonationProjectForSelect(
    donationDetailsData.projects,
    languageDonationDetailsData.body,
    { onlyShowLanguageProject: true }
  );

  React.useState(() => {
    if (typeof window !== 'undefined') {
      getLanguageProjectRaisedMoney('api/donate/total-donations-by-child-project', {
        onSuccess: () => {},
        onError: () => {},
      });
    }
  }, []);

  const getPercentageOfRaisedMoney = (projectId, totalAmount) => {
    const raisedMoneyForProject = getRaisedMoneyForProject(projectId, totalAmount);
    const percentage = (raisedMoneyForProject / totalAmount) * 100;

    return Math.ceil(percentage) + '%';
  };

  const getRaisedMoneyForProject = (projectId) => {
    if (projectId) {
      const findProject = raisedMoneyDataForLanguage?.data?.find((item) => item._id === projectId);
      const raisedMoney = findProject?.totalAmount ? findProject.totalAmount : 0;
      return raisedMoney;
    }
    return 0;
  };

  const categories = ['AFRICA', 'ASIA', 'EUROPE', 'NORTH AMERICA', 'SOUTH AMERICA'];
  const selectedProject = customProjectsLists.find((item) =>
    donatingProjectId ? donatingProjectId === item.childProjectId : item.childProjectId
  );

  const onFilterClicked = (searchKey) => {
    if (searchKey) {
      const selectedForSearch = projectsLists.find((item) => item.value === searchKey.value);
      const filteredProjects = languageSortData.filter((item) => {
        return (
          item.primary.languageName?.text.toLowerCase().includes(selectedForSearch.childProjectName.toLowerCase()) ||
          item.primary.flagCode?.text.toLowerCase().includes(selectedForSearch.flagCode.toLowerCase())
        );
      });
      setAllDonationLanguageProject(filteredProjects);
    }
  };

  const filteredLanguageProject = allDonationLanguageProject.filter((filteringItem) => {
    const languageContinent = filteringItem.items.map((item) => item.continent.toLowerCase());
    return selectedCat === 'all' ? true : languageContinent.includes(selectedCat.toLowerCase());
  });

  console.log(languageDonationDetailsData, 'languageDonationDetailsData');

  const languageDetail = languageDonationDetailsData?.detail.html;
  const regex = /{{(\d+)}}/g;

  const newLanguageDetail = languageDetail.replace(regex, (match, p1) => {
    const number = parseInt(p1, 10);
    const multipliedNumber =
      (currencySymbolMap[currency] || '$') + getFormatNumberWithCommas(Math.ceil(selectedRate.rate * number));
    return `${multipliedNumber}`;
  });

  return (
    <BodyClassName className="body-light page-give language-donation-page">
      <DefaultLayoutComponent
        title={languageDonationDetailsData.title.text}
        description={languageDonationDetailsData?.details?.text}
      >
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner over_lpd-banner bgImage bg-pos-center-top"
            fluid={languageDonationDetailsData.backgroundImage.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="pb-container-donate ">
                <div className="pb-content">
                  <h1 className="p-xl donate-heading">{languageDonationDetailsData.title.text}</h1>
                  {/* <h1 className="donate-sub-title">{languageDonationDetailsData.subTitle.text}</h1> */}
                </div>
              </div>
            </div>
          </BackgroundImage>

          <section className="give-container  over_lpd-container bg-dark pd-0">
            <div className="categories-watch  bg-dark">
              <div className="wrapper wrapper-xl pd-x-md pd-x-sm-20 " style={{ zIndex: 10 }}>
                <div className="bg-white project-descriptions-container ">
                  <div className="project-descriptions pd-x-md">
                    <div>
                      <p>{renderHtml(decode(newLanguageDetail))}</p>
                      <Link to={`${'#donate-box'}`} className="btn btn-md btn-primary-ii donate-button ">
                        Give
                      </Link>
                    </div>
                  </div>
                  {languageDonationDetailsData?.youtubeId?.text && (
                    <div className="wrapper-lg wrapper" style={{ marginTop: 80 }}>
                      <LanguageVideoItem
                        videoId={languageDonationDetailsData.youtubeId.text}
                        thumbnailURL={languageDonationDetailsData.videoThumbNail.fluid.src}
                      />
                    </div>
                  )}
                </div>
                <div className=" search-container">
                  <ComboBoxSelect
                    options={projectsLists}
                    placeHolder={'Search language projects'}
                    name="projectId"
                    buttonClass={'search-input'}
                    value={selectedDonatedProject}
                    onChange={(value) => {
                      setSelectedProject(value);

                      if (value?.label) {
                        onFilterClicked(value);
                      } else {
                        setAllDonationLanguageProject(languageSortData);
                      }
                    }}
                    className="w-full"
                  ></ComboBoxSelect>
                  {/* <button
                            className="btn btn-md search-button  btn-primary-li btn-tour-jumbotron "
                            onClick={onFilterClicked}>
                            <img src={iconArrowRight} />
                          </button> */}
                  {/* </div> */}
                  {/* <input
                    className="search-input m-t-16"
                    type="text"
                    name="searchKey"
                    placeholder="Search language projects"
                    value={searchKey}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setAllDonationLanguageProject(languageDonationDetailsData.body);
                      }
                      setSearchKey(e.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        // Perform the action here, for example:
                        onFilterClicked();
                      }
                    }}
                  /> */}
                  {/* <button
                    className="btn btn-md btn-primary-li btn-tour-jumbotron "
                    onClick={onFilterClicked}
                    style={{ height: '50px' }}>
                    <img src={iconArrowRight} />
                  </button> */}
                </div>
                <div className="filter-categories  ">
                  <WatchFilterSlider categories={categories} onCatSelected={setSelectedCat} selectedCat={selectedCat} />
                </div>
              </div>
            </div>
            <div className="wrapper wrapper-xl pd-x-sm-20">
              <div>
                <div className="wrapper wrapper-xl  cat_watch-list" style={{ zIndex: 1 }}>
                  {filteredLanguageProject.length !== 0 ? (
                    <div className="d-flex columns-3 ">
                      {filteredLanguageProject.map((item) => {
                        return (
                          <>
                            <LanguageDonationCard
                              raisedMoney={getRaisedMoneyForProject(item.id)}
                              languageDonationCard={item.primary}
                              raisedPercentage={getPercentageOfRaisedMoney(item.id, +item.primary.goal)}
                              languageProjectId={item.id}
                            />
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <p className="no-project-text">
                        There are no any language translation <br /> projects for this continent
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div id="donate-box" className="donate-box-container-language-details-page">
                <Donation
                  isCustomDonation={true}
                  patreonThumb={patreonThumb}
                  showTab={false}
                  projectsLists={customProjectsLists}
                  defaultSelected={selectedProject}
                  hideTaxDeductibleTab={true}
                />
              </div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    patreonThumb: file(relativePath: { eq: "patreon-thumb.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    donationsDetails: allPrismicDonationsPage {
      edges {
        node {
          data {
            projects {
              title {
                text
              }
              thumbnail {
                url
              }
              detailsPage: details_page {
                id
                slug
                uid
                document {
                  __typename
                }
              }
            }
          }
        }
      }
    }
    languageDonationPage: allPrismicLanguageDonationsPage {
      edges {
        node {
          data {
            title {
              text
            }
            youtubeId: youtube_id {
              text
            }
            videoThumbNail: video_thumbnail {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            subTitle: sub_title {
              text
            }

            detail {
              html
              text
            }
            backgroundImage: background_image {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            titleImage: title_image {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            body {
              ... on PrismicLanguageDonationsPageBodyLanguageProjects {
                slice_type
                slice_label
                id
                primary {
                  languageName: language_name {
                    text
                  }
                  continent
                  shortDescriptions: short_descriptions {
                    text
                  }
                  flagCode: flag_code {
                    text
                  }
                  goal
                }
                items {
                  continent
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default LanguageDonationPage;
